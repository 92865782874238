// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { Pill } from "components/elements";
import { Avatar } from "components/images";
import {
  TableRow,
  TableRowColumn,
  TableRowColumnOverflow,
} from "components/layout";
import { Link } from "components/typography";
import { trans } from "src/translations";

import styles from "../_shared/UsersTable.scss";
import { getFullName, getOrganizationNamesString } from "../_utils";
import { UserActionMenu } from "./UserActionMenu";

const overflowText = (user, is_active) => {
  return (
    <>
      {getFullName(user)}
      {!is_active && (
        <Pill text={trans.USER_ADMIN__DEACTIVATED()} icon="users" />
      )}
    </>
  );
};

export const UserRow = ({ setActive, user }) => {
  const { id, avatar, organizationNames, is_active } = user;
  const actionPath = `/users/admin/${id}/edit`;

  return (
    <TableRow>
      <TableRowColumn>
        <Avatar size="24px" src={avatar} />
      </TableRowColumn>
      <TableRowColumnOverflow overflowText={overflowText(user, is_active)}>
        <Link to={actionPath} className={styles.userName}>
          {getFullName(user)}
        </Link>
        {!is_active && (
          <Pill text={trans.USER_ADMIN__DEACTIVATED()} icon="users" />
        )}
      </TableRowColumnOverflow>
      <TableRowColumnOverflow columnClass={styles.organizationsColumn}>
        {getOrganizationNamesString(organizationNames)}
      </TableRowColumnOverflow>
      <TableRowColumn>
        <UserActionMenu user={user} setActive={setActive} />
      </TableRowColumn>
    </TableRow>
  );
};

UserRow.propTypes = {
  setActive: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    avatar: PropTypes.string,
    given_name: PropTypes.string,
    family_name: PropTypes.string,
    is_active: PropTypes.bool,
    organizationNames: PropTypes.array.isRequired,
  }).isRequired,
};
