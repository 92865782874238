// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { SearchBar } from "components/elements";
import { Info } from "components/feedback";
import { Section, TableBody } from "components/layout";
import { TabbedTable } from "containers/TabbedTable/TabbedTable";
import { trans } from "src/translations";
import { withSearch, withRouter } from "utils/decorators";

import styles from "../_shared/UsersTable.scss";
import { UserRow } from "./UserRow";

@withSearch
@withRouter
export class UsersTableRend extends Component {
  static propTypes = {
    // from parent data component
    users: PropTypes.array.isRequired,
    count: PropTypes.number.isRequired,
    error: PropTypes.object,
    loading: PropTypes.bool.isRequired,

    // from @withSearch
    searchValue: PropTypes.string,
    changeSearch: PropTypes.func.isRequired,
    //@withRouter
    location: PropTypes.object.isRequired,
  };

  static tabsConfig = [
    {
      dataTestId: "users-list",
      columns: [
        {
          label: trans.ICON(),
          dataTestId: "column-header-icon",
          className: styles.avatarColumn,
        },
        {
          label: trans.NAME(),
          dataTestId: "column-header-name",
          className: styles.nameColumn,
        },
        {
          label: trans.ROLES(),
          dataTestId: "column-header-roles",
          className: styles.roleColumn,
        },
        {
          label: "Last login",
          dataTestId: "column-header-last-login",
          className: styles.lastLoginColumn,
        },
        {
          id: "actions",
          label: "",
          dataTestId: "column-header-action-menu",
          className: styles.optionsColumn,
        },
      ],
    },
  ];

  renderBody = (users) => {
    return (
      <TableBody>
        {users.map((user) => (
          <UserRow key={user.id} user={user} />
        ))}
      </TableBody>
    );
  };

  render() {
    const {
      users,
      count,
      error,
      location,
      loading,
      searchValue,
      changeSearch,
    } = this.props;

    const locationMessage = location.state?.message || "";
    return (
      <div>
        <Section header={trans.USER_LIST__SECTION_NAME()}>
          <Section>
            <SearchBar
              value={searchValue}
              onSearch={changeSearch}
              placeholder={trans.USER_LIST__SEARCH_PLACEHOLDER()}
            />
          </Section>
          <Section>{locationMessage && <Info>{locationMessage}</Info>}</Section>
          <Section>
            <TabbedTable
              tabsConfig={UsersTableRend.tabsConfig}
              data={{ results: users, count, loading, error }}
              renderTableBody={this.renderBody}
              layout="fixed"
            />
          </Section>
        </Section>
      </div>
    );
  }
}
