// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import PropTypes from "prop-types";

import { classes } from "utils/classes";

import styles from "./InfoBox.scss";

export class InfoBox extends PureComponent {
  static propTypes = {
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
      .isRequired,
    type: PropTypes.oneOf(["error", "info", "success"]),
    dataTestId: PropTypes.string,
  };

  static defaultProps = {
    type: "info",
    dataTestId: "info-box",
  };

  render() {
    const { message, type, dataTestId } = this.props;
    return (
      <div
        className={classes(styles[type], styles.container)}
        data-test-id={`${dataTestId}-${type}`}
      >
        <p>{message}</p>
      </div>
    );
  }
}
