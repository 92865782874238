// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent, Fragment } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { Info } from "components/feedback";
import { Section } from "components/layout";
import { trans } from "src/translations";

import { APP_TYPES } from "../../constants";
import { withVersions } from "../AppVersions/_shared/withVersionsDecorator";
import { CertificationAndDistributionType } from "./CertificationAndDistributionType";
import { ContentManagerApproval } from "./ContentManagerApproval";
import { SalesforceAgreementsData } from "./SalesforceAgreementsData";

@withVersions
@connect(
  ({ auth }) => ({
    userId: auth.profile.id,
  }),
  null
)
export class RightsAndContractTab extends PureComponent {
  static propTypes = {
    refreshQASummaries: PropTypes.func.isRequired,
    qaSummaries: PropTypes.object,
    application: PropTypes.shape({
      type: PropTypes.string.isRequired,
    }).isRequired,

    // from @connect
    userId: PropTypes.string.isRequired,

    // from @withVersions
    refreshVersions: PropTypes.func.isRequired,
    versionsLoading: PropTypes.bool.isRequired,
    versionsError: PropTypes.string,
    activeVersion: PropTypes.object,
  };

  componentDidMount() {
    this.getLatestVersionData();
  }

  getLatestVersionData = () => {
    const { application, refreshVersions } = this.props;
    refreshVersions(application);
  };

  render() {
    const {
      qaSummaries: {
        id: qaSummariesId,
        accepted,
        latest_moderation_result: latestModerationResult,
      },
      refreshQASummaries,
      application: { type },
      userId,
      versionsLoading,
      versionsError,
      activeVersion,
    } = this.props;

    if (versionsLoading) {
      return (
        <div>
          <Loader />
        </div>
      );
    }

    if (versionsError) {
      return (
        <div>
          <Info type="error">{trans.DEFAULT_ERROR_MESSAGE()}</Info>
        </div>
      );
    }

    const {
      is_certifiable: isCertifiable,
      is_distributable: isDistributable,
      for_examination_only: isForExaminationOnly,
    } = activeVersion;

    const isApproved = accepted === true;
    const isRejected = accepted === false;

    const isHtml = type === APP_TYPES.HTML;
    const isCertificationOnly = isCertifiable && !isDistributable;
    const hasAgreements =
      isForExaminationOnly || (isHtml && isCertificationOnly);
    const shouldRenderAgreements = isApproved && hasAgreements;

    return (
      <Fragment>
        <Section header={trans.APP_DETAILS_RIGHTS__HEADER()}>
          <CertificationAndDistributionType
            appType={type}
            isCertifiable={isCertifiable}
            isDistributable={isDistributable}
            isForExaminationOnly={isForExaminationOnly}
          />

          {!isDistributable && (
            <ContentManagerApproval
              qaSummariesId={qaSummariesId}
              refreshData={refreshQASummaries}
              userId={userId}
              isApproved={isApproved}
              isRejected={isRejected}
              isForExaminationOnly={isForExaminationOnly}
            />
          )}
        </Section>

        {shouldRenderAgreements && (
          <SalesforceAgreementsData
            qaSummariesId={qaSummariesId}
            latestModerationResult={latestModerationResult}
          />
        )}
      </Fragment>
    );
  }
}
