// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component, Fragment } from "react";

import PropTypes from "prop-types";

import { InfoBox } from "components/layout";
import { getVersionSubTabFromUrl } from "pages/Applications/AppDetails/AppVersions/_shared/getVersionSubTabFromUrl";
import { VERSION_TABS } from "pages/Applications/AppDetails/AppVersions/constants";
import { QA_STATUS_REJECTED } from "pages/Applications/AppDetails/constants";
import { trans } from "src/translations";
import { withRouter } from "utils/decorators/withRouter";

import { VersionSubTabs } from "./AppVersions/_shared/VersionSubTabs";
import { withVersions } from "./AppVersions/_shared/withVersionsDecorator";
import { SectionEnvironmentsData } from "./Moderation/SectionEnvironmentsData";
import { SectionTargetsData } from "./Moderation/SectionTargetsData";

@withRouter
@withVersions
export class ModerationTargetsTab extends Component {
  static propTypes = {
    application: PropTypes.object.isRequired,
    qaSummaries: PropTypes.object.isRequired,
    refreshQASummaries: PropTypes.func.isRequired,

    // from @withRouter
    location: PropTypes.object,

    // from @withVersions
    refreshVersions: PropTypes.func.isRequired,
    versionsLoading: PropTypes.bool.isRequired,
    versionsError: PropTypes.string,
    activeVersion: PropTypes.object,
    latestVersion: PropTypes.object,
  };

  async componentDidMount() {
    this.refreshVersionsData();
  }

  refreshVersionsData = () => {
    const { refreshVersions, application } = this.props;
    refreshVersions(application);
  };

  getSelectedAppVersionKey = () => {
    const { location } = this.props;
    return getVersionSubTabFromUrl(location);
  };

  getSelectedAppVersion = () => {
    const { activeVersion, latestVersion } = this.props;
    return this.getSelectedAppVersionKey() === VERSION_TABS.active
      ? activeVersion
      : latestVersion;
  };

  render() {
    const {
      qaSummaries,
      application,
      refreshQASummaries,
      versionsLoading,
      versionsError,
      activeVersion,
      latestVersion,
    } = this.props;

    const selectedAppVersion = this.getSelectedAppVersion();
    const selectedAppVersionKey = this.getSelectedAppVersionKey();
    const isAppRejected = () =>
      selectedAppVersion.qa_status === QA_STATUS_REJECTED;

    return (
      <Fragment>
        <VersionSubTabs
          loading={versionsLoading}
          error={versionsError}
          activeVersion={activeVersion}
          latestVersion={latestVersion}
          application={application}
        >
          {({ renderTabsList }) => {
            return (
              <Fragment>
                {renderTabsList()}
                {isAppRejected() ? (
                  <InfoBox
                    message={trans.APP_DETAILS_MODERATION_TARGETS__REJECTED_INFO()}
                  />
                ) : (
                  <Fragment>
                    <SectionEnvironmentsData
                      application={application}
                      appVersion={selectedAppVersion}
                      refreshVersionsData={this.refreshVersionsData}
                    />
                    <SectionTargetsData
                      qaSummaries={qaSummaries}
                      application={application}
                      refreshQASummaries={refreshQASummaries}
                      refreshVersionsData={this.refreshVersionsData}
                      versionsLoading={versionsLoading}
                      versionsError={versionsError}
                      selectedAppVersion={selectedAppVersion}
                      selectedAppVersionKey={selectedAppVersionKey}
                    />
                  </Fragment>
                )}
              </Fragment>
            );
          }}
        </VersionSubTabs>
      </Fragment>
    );
  }
}
