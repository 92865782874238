// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment, Component } from "react";

import isEqual from "lodash-es/isEqual";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Loader } from "components/elements";
import { SearchableList } from "components/elements/SearchableList";
import { Modal } from "components/layout";
import { InfiniteData } from "containers/InfiniteData";
import { trans } from "src/translations";

import styles from "./DevicesModal.scss";
import { OrganizationRow } from "./OrganizationRow";

export class DevicesModal extends Component {
  static propTypes = {
    addAssetDevice: PropTypes.shape({
      isActive: PropTypes.bool.isRequired,
      versionNumber: PropTypes.string,
      asset: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        defaultVersion: PropTypes.string,
      }),
    }),
    publishedModels: PropTypes.arrayOf(PropTypes.string).isRequired,
    devicesErrors: PropTypes.object,
    isLoading: PropTypes.bool.isRequired,
    addAssetDeviceInactive: PropTypes.func,
    postAssetDeviceModels: PropTypes.func,
    fetchData: PropTypes.func,
    itemsPerPage: PropTypes.number.isRequired,
  };

  state = {
    publishing: false,
    error: null,
    checkedModels: [],
  };

  componentDidUpdate(prevProps) {
    const { publishedModels } = this.props;

    if (!isEqual(prevProps.publishedModels, publishedModels)) {
      this.setState({
        checkedModels: publishedModels,
      });
    }
  }

  isModelChecked = (modelId) => {
    return this.state.checkedModels.includes(modelId);
  };

  onModelChecked = (modelId) => {
    const { checkedModels } = this.state;

    if (this.isModelChecked(modelId)) {
      this.setState({
        checkedModels: checkedModels.filter((m) => m !== modelId),
      });
    } else {
      this.setState({
        checkedModels: [...checkedModels, modelId],
      });
    }
  };

  handlePublish = async () => {
    const {
      postAssetDeviceModels,
      addAssetDevice: { asset, versionNumber },
      addAssetDeviceInactive,
    } = this.props;
    const { checkedModels } = this.state;

    const body = {
      data: checkedModels.map((id) => ({
        type: "software_asset_version_models",
        id: id,
      })),
    };

    this.setState({ publishing: true });

    const { error } = await postAssetDeviceModels(
      asset.id,
      versionNumber || asset.defaultVersion,
      {
        body: JSON.stringify(body),
      }
    );

    if (error) {
      this.setState({
        publishing: false,
        error: error.message,
      });
    } else {
      this.setState({ publishing: false, error: null });
      addAssetDeviceInactive();
    }
  };

  isPublishDisabled() {
    const { publishedModels } = this.props;
    const { checkedModels } = this.state;
    return (
      checkedModels.length === publishedModels.length &&
      publishedModels.every((id) => checkedModels.includes(id))
    );
  }

  renderModalContent() {
    const {
      addAssetDevice,
      fetchData,
      devicesErrors,
      itemsPerPage,
      isLoading,
    } = this.props;
    const { asset, versionNumber } = addAssetDevice;
    const error = this.state.error || devicesErrors;

    if (isLoading) {
      return <Loader />;
    }

    return (
      <Fragment>
        <h1 className={styles.header}>
          {trans.ASSET__SELECT_DEVICE_FOR_HEADER()}{" "}
          <span className={styles.asset}>
            {asset.name} - {trans.VERSION()}{" "}
            {versionNumber || asset.defaultVersion}
          </span>
        </h1>
        <div>
          <InfiniteData fetchData={fetchData} itemsPerPage={itemsPerPage}>
            {({
              data,
              pending,
              error,
              allDataLoaded,
              handleScroll,
              handleSearchChange,
            }) => (
              <SearchableList
                list={data}
                classNameList={styles.searchableList}
                onScroll={handleScroll}
                onSearchChange={handleSearchChange}
                withLoader={true}
                loading={pending}
                allDataLoaded={allDataLoaded}
                error={error.message}
              >
                {(organization) => (
                  <OrganizationRow
                    key={organization.id}
                    organization={organization}
                    isModelChecked={this.isModelChecked}
                    onModelChecked={this.onModelChecked}
                  />
                )}
              </SearchableList>
            )}
          </InfiniteData>
        </div>
        {error && (
          <div className={styles.errorMessage} data-test-id="error">
            {error.message || error}
          </div>
        )}
      </Fragment>
    );
  }

  render() {
    const { addAssetDevice, addAssetDeviceInactive } = this.props;
    const { publishing } = this.state;

    return (
      <Modal
        title={trans.ASSET__PUBLISH_TO_DEVICE_TITLE()}
        isOpen={addAssetDevice.isActive}
        onClose={addAssetDeviceInactive}
        actions={
          <ButtonsWrapper>
            <Button type="normal" onClick={addAssetDeviceInactive}>
              {trans.CANCEL()}
            </Button>
            <Button
              type="green"
              processing={publishing}
              onClick={this.handlePublish}
              disabled={this.isPublishDisabled()}
            >
              {trans.PUBLISH()}
            </Button>
          </ButtonsWrapper>
        }
      >
        {this.renderModalContent()}
      </Modal>
    );
  }
}
