// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import { Formik, Form, Field } from "formik";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { Uploader } from "components/form";
import { RadioGroup } from "components/form/elements";
import { TextareaField, TextField } from "components/form/fields";
import { Modal, Section } from "components/layout";
import { trans } from "src/translations";
import { isUrl, hasValidURIChars } from "utils/validation";

import styles from "./AddContentItemModal.scss";
import { FIELDS, CONTENT_TYPES, CONTENT_RADIOS } from "./constants";
import {
  validateVideoUrlThumbnail,
  validateFullPageLandscapeField,
  landscapeImgDimensions,
} from "./validation";

/** CS-1961: BACKEND: depplink validation error source pointers should
 * be rewritten to allow for highlighing specific deeplink fields
 * ==== implement error propagation in UI when CS-1961 done
 * */

const fieldNameMap = {
  videoUrlThumbnail: "videoUrlThumbnail.url",
  fullPageLandscape: "fullPageLandscape.url",
};

export class AddContentItemModal extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
  };

  submitForm = (values) => {
    /** below is necessary to allow for
     * editing promotion type in edit mode*/
    if (values.contentType === "full_page_landscape") {
      delete values.videoUrl;
      delete values.videoUrlThumbnail;
    } else {
      delete values.fullPageLandscape;
    }

    const { onSubmit } = this.props;
    onSubmit(values);
  };

  renderContentItem = (contentType) => {
    if (contentType === CONTENT_TYPES.VIDEO_URL) {
      return (
        <Section
          header={trans.PROMOTION_DETAILS__CONTENT_VIDEO_URL()}
          className={styles.formSection}
        >
          <TextField {...FIELDS.videoUrl} />
          <Uploader
            name="videoUrlThumbnail"
            label={trans.PROMOTION_DETAILS__FIELD_THUMBNAIL()}
            formats={["image/jpeg", "image/png"]}
            info={trans.PROMOTION_DETAILS__CONTENT_ITEM_THUMBNAIL_INFO({
              aspectRatio: "16:9",
              maxSize: 2,
            })}
            validate={validateVideoUrlThumbnail}
          />
        </Section>
      );
    }

    return (
      <Section
        header={trans.PROMOTION_DETAILS__CONTENT_FULL_PAGE_LANDSCAPE()}
        className={styles.formSection}
      >
        <Uploader
          name="fullPageLandscape"
          label={trans.PROMOTION_DETAILS__FIELD_FULL_PAGE_LANDSCAPE()}
          tooltip={trans.PROMOTION_DETAILS__FIELD_FULL_PAGE_LANDSCAPE_TOOLTIP()}
          formats={["image/jpeg"]}
          info={trans.PROMOTION_DETAILS__FIELD_FULL_PAGE_LANDSCAPE_INFO({
            format: "jpeg",
            ...landscapeImgDimensions,
            aspectRatio: "16:9",
          })}
          validate={validateFullPageLandscapeField}
        />
      </Section>
    );
  };

  renderContentTypeRadio = () => (
    <Field name="contentType">
      {({ field, meta }) => (
        <RadioGroup
          {...field}
          {...meta}
          label={trans.PROMOTION_DETAILS__CONTENT_SELECT()}
          radios={CONTENT_RADIOS}
          required={true}
        />
      )}
    </Field>
  );

  validate = (values) => {
    const validate = (cond, val, name) => (cond ? val(name)(values) : {});
    const shouldValUrl = values?.contentType === "video" && values?.videoUrl;
    return {
      ...validate(shouldValUrl, isUrl, "videoUrl"),
      ...validate(values?.deepLinkUrl, hasValidURIChars, "deepLinkUrl"),
    };
  };

  render() {
    const { onClose, initialValues } = this.props;

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={() => {}} // this is required even if not used; otherwise Formik will throw error
        validate={this.validate}
        initialTouched={{
          deepLinkUrl: true,
        }}
      >
        {({
          values,
          isSubmitting,
          dirty,
          errors,
          touched,
          validateForm,
          setFieldTouched,
        }) => {
          const error = Object.keys(errors).some((field) =>
            Object.keys(touched).includes(field)
          );

          const onSubmitBtnClick = async () => {
            const errors = await validateForm();
            const invalidFields = Object.keys(errors);
            if (invalidFields.length) {
              invalidFields.forEach((field) => {
                setFieldTouched(fieldNameMap[field] ?? field);
              });
            } else {
              this.submitForm(values);
            }
          };

          const actionButtons = (
            <ButtonsWrapper>
              <Button
                type="normal"
                onClick={onClose}
                disabled={isSubmitting}
                dataTestId="cancel-button"
              >
                {trans.CANCEL()}
              </Button>
              <Button
                type="green"
                disabled={isSubmitting || !dirty}
                processing={isSubmitting}
                onClick={onSubmitBtnClick}
                dataTestId="add-button"
              >
                {trans.ADD()}
              </Button>
            </ButtonsWrapper>
          );

          return (
            <Modal
              title={trans.PROMOTION_DETAILS__CONTENT_DEFINE()}
              isOpen={true}
              onClose={onClose}
              size="big"
              actions={actionButtons}
            >
              <Form aria-label="form">
                <Section
                  header={trans.PROMOTION_DETAILS__CONTENT_ITEMS_ELEMENT()}
                  className={styles.formSection}
                >
                  {this.renderContentTypeRadio()}
                </Section>

                {this.renderContentItem(values.contentType)}

                <Section
                  header={trans.PROMOTION_DETAILS__CONTENT_OPTIONAL_ELEMENTS()}
                  className={styles.formSection}
                >
                  <TextField {...FIELDS.deepLinkUrl} />
                  <TextField {...FIELDS.customTitle} />
                  <TextareaField {...FIELDS.customDescription} />
                </Section>

                {error && (
                  <Info type="error">{trans.DEFAULT_ERROR_MESSAGE()}</Info>
                )}
              </Form>
            </Modal>
          );
        }}
      </Formik>
    );
  }
}
