// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { trans } from "src/translations";
import { classes } from "utils/classes";

import styles from "./SearchableList.scss";

/**
 * One of the most used components. Displays list that can be searched. Handles data loading state. Allows to handle scroll event.
 *
 * Additional components:
 *
 * - `<SearchableListPanel>` &mdash; used to wrap rows
 * - `<SearchableListFooter>` &mdash; footer
 * - `<CheckAllButton>` &mdash; used with list when each can be checked
 * - `<SelectedCountFooter>` &mdash; used with list when each can be checked
 * - `<InfiniteData>` &mdash; when list of items is paginated. Adds items on scroll
 */
export class SearchableList extends Component {
  static propTypes = {
    /**
     * If not provided, internal search disabled so 'onSearchChange' function has to be provided
     */
    searchBy: PropTypes.string,
    /** Data to display. There are no requirements on content */
    list: PropTypes.array,
    /**
     * Function to render each row.
     * Type: (item: T, index: number) => React.Element
     */
    children: PropTypes.func,
    dataTestId: PropTypes.string,
    className: PropTypes.string,
    /** Class name for items list (if the list is displayed) */
    classNameList: PropTypes.string,
    onScroll: PropTypes.func,
    onSearchChange: PropTypes.func,
    /**
     * Show additional state information. Mostly used when data is dynamic.
     * This includes e.g. usage with `<InfinteData>`
     */
    withLoader: PropTypes.bool,
    /** True if mora data is being loaded. Shown only if `withLoader` is true */
    loading: PropTypes.bool,
    /**
     * Indicate that all data was loaded. Show it as message to the user.
     * Shown only if `withLoader` is true
     */
    allDataLoaded: PropTypes.bool,
    /** Error string to show. Shown only if `withLoader` is true */
    error: PropTypes.string,
    /**
     * Additional element to display between searchbar and the list.
     * Function that takes current list data as an argument.
     * Often it's `<CheckAllButton>` if each list item is checkable.
     */
    optionsListElement: PropTypes.func,
    /** Search placeholder */
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    onScroll: () => {},
    onSearchChange: () => {},
    loading: false,
    allDataLoaded: true,
    list: [],
    dataTestId: "searchable-list",
    withLoader: false,
    placeholder: trans.SEARCH(),
  };

  state = { searchPhrase: "" };

  searchList(results, searchPhrase) {
    const { searchBy } = this.props;

    if (!searchBy) {
      return results;
    }

    return results.filter((row) => {
      const rowText = row[searchBy].toLowerCase();
      return rowText.includes(searchPhrase.toLowerCase());
    });
  }

  renderList(items) {
    const { classNameList, onScroll, children } = this.props;
    return (
      <ul
        className={classes(styles.searchList, classNameList)}
        onScroll={onScroll}
      >
        {items.map(children)}
      </ul>
    );
  }

  renderEmptyList() {
    const { classNameList } = this.props;
    return (
      <div className={classes(styles.listNoItemsMessage, classNameList)}>
        <div className={styles.listNoItemsMessageText}>
          {trans.TABLE_EMPTY()}
        </div>
      </div>
    );
  }

  renderDynamicListLoader() {
    const { loading, allDataLoaded, error } = this.props;
    return (
      <div className={styles.listFooter}>
        {loading && <Loader size="small" message={`${trans.LOADING()}...`} />}
        {!loading && allDataLoaded && <span>{trans.ALL_DATA_LOADED()}</span>}
        {error && (
          <span className={styles.error} data-test-id="error">
            {error}
          </span>
        )}
      </div>
    );
  }

  render() {
    const {
      list,
      dataTestId,
      className,
      onSearchChange,
      withLoader,
      loading,
      optionsListElement,
      placeholder,
    } = this.props;
    const { searchPhrase } = this.state;
    const filteredList = this.searchList(list, searchPhrase);

    return (
      <div
        data-test-id={dataTestId}
        className={classes(styles.searchableList, className)}
      >
        <div className={styles.searchBar}>
          <input
            data-test-id={`${dataTestId}-search-bar-input`}
            type="text"
            placeholder={placeholder}
            value={searchPhrase}
            onChange={(e) => {
              this.setState({ searchPhrase: e.target.value });
              onSearchChange(e.target.value);
            }}
          />
        </div>
        {optionsListElement && optionsListElement(filteredList)}
        {filteredList.length > 0 && this.renderList(filteredList)}
        {!loading && filteredList.length === 0 && this.renderEmptyList()}
        {withLoader && this.renderDynamicListLoader()}
      </div>
    );
  }
}
