// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import { parseISO } from "date-fns";
import PropTypes from "prop-types";

import { Avatar } from "components/images";
import {
  TableRow,
  TableRowColumn,
  TableRowColumnOverflow,
} from "components/layout";
import { Link } from "components/typography";
import { withActiveOrganization } from "containers/Auth/decorators";
import { profilePropTypes } from "containers/Auth/propTypes";
import { checkAccess } from "containers/Permissions";
import { isAllowedToEditUser } from "containers/Permissions/groups";

import { formatDateTime } from "../../../utils/date";
import styles from "../_shared/UsersTable.scss";
import { getFullName } from "../_utils";
import { RolesColumn } from "./RolesColumn";
import { UserActionMenu } from "./UserActionMenu";

@withActiveOrganization
@connect(({ auth }) => ({
  currentUser: auth.profile,
}))
export class UserRow extends Component {
  static propTypes = {
    user: PropTypes.shape({
      id: PropTypes.string.isRequired,
      avatar: PropTypes.string,
      email: PropTypes.string.isRequired,
      given_name: PropTypes.string,
      family_name: PropTypes.string,
      is_active: PropTypes.bool,
      roles: PropTypes.array.isRequired,
      last_login: PropTypes.string,
    }).isRequired,
    activeOrganization: PropTypes.shape({
      roles: PropTypes.array.isRequired,
    }),
    currentUser: profilePropTypes,
  };

  render() {
    const { activeOrganization, currentUser, user } = this.props;
    const { id, avatar, roles } = this.props.user;

    const canEdit = checkAccess({ activeOrganization, currentUser }, [
      isAllowedToEditUser(user),
    ]);
    const actionPath = canEdit ? `/users/${id}/edit` : `/users/${id}/details`;

    return (
      <TableRow>
        <TableRowColumn>
          <Avatar size="24px" src={avatar} />
        </TableRowColumn>
        <TableRowColumnOverflow overflowText={getFullName(user)}>
          <Link to={actionPath} className={styles.userName}>
            {getFullName(user)}
          </Link>
        </TableRowColumnOverflow>
        <TableRowColumn>
          <RolesColumn roles={roles} />
        </TableRowColumn>
        <TableRowColumn>
          {user.last_login
            ? formatDateTime(parseISO(String(user.last_login)))
            : "-"}
        </TableRowColumn>
        <TableRowColumn>
          <UserActionMenu user={user} />
        </TableRowColumn>
      </TableRow>
    );
  }
}
