// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Button } from "components/buttons";
import { Header } from "components/typography";
import { trans } from "src/translations";

import noOrganizationImg from "./noOrganization.png";
import styles from "./noOrganization.scss";

export const NoOrganization = () => (
  <div className={styles.container} data-test-id="no-organization-info">
    <div className={styles.content}>
      <img className={styles.image} src={noOrganizationImg} />
      <Header bold={true} className={styles.header}>
        {trans.PERMISSIONS__NO_ORGANIZATION_FALLBACK_HEADER()}
      </Header>
      <p className={styles.info}>
        {trans.PERMISSIONS__NO_ORGANIZATION_FALLBACK_INFO()}
      </p>
      <Button to="/organization/create" className={styles.button} type="green">
        {trans.PERMISSIONS__NO_ORGANIZATION_FALLBACK_BUTTON()}
      </Button>
    </div>
  </div>
);
