// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { classes } from "utils/classes";

import styles from "./info.scss";

export class Info extends Component {
  static propTypes = {
    className: PropTypes.string,
    type: PropTypes.oneOf(["error", "normal", "warning"]),
    dataTestId: PropTypes.string,
    children: PropTypes.node,
  };

  static defaultProps = {
    type: "normal",
    dataTestId: "info",
  };

  getARIAProps = () => {
    switch (this.props.type) {
      case "error":
        return { role: "alert" };
      case "warning":
        return { role: "status" };
      default:
        return {};
    }
  };

  render() {
    const { className, type, dataTestId, children } = this.props;

    return (
      <div
        {...this.getARIAProps()}
        className={classes(styles[type] || styles.normal, className)}
        data-test-id={`${dataTestId}-${type}`}
      >
        {children}
      </div>
    );
  }
}

export default Info;
