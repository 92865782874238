// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";
import { connect } from "react-redux";

import { Formik, Form } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { Label } from "components/form";
import { TextField } from "components/form/fields";
import { Modal } from "components/layout";
import { apiThatThrows } from "containers/Request";
import { trans } from "src/translations";
import { urlRegex } from "src/utils/validation/validators";
import { prepareErrorsForForm } from "utils/errors";
import { createBody } from "utils/jsonApi";

import { OrganizationField } from "./OrganizationField";

const jsonPointerToFieldName = {
  "/data/attributes/salesforce_id": "salesforceId",
  "/data/attributes/non_field_errors": "salesforceId",
  "/data/attributes/organization_id": "organizationId",
};

const FORM_FIELDS = {
  salesforceId: {
    name: "salesforceId",
    look: "simple",
    label: trans.APP_DETAILS_RIGHTS__AGREEMENTS_MODAL_LABEL_SALESFORCE_ID(),
  },
  organizationId: {
    name: "organizationId",
  },
  salesforceUrl: {
    name: "salesforceUrl",
    label: trans.APP_DETAILS_RIGHTS__AGREEMENTS_MODAL_LABEL_SALESFORCE_URL(),
  },
};

const SalesforceAgreementSchema = Yup.object().shape({
  salesforceId: Yup.string().required("Required"),
  organizationId: Yup.string().required("Required"),
  salesforceUrl: Yup.string().matches(urlRegex, "Invalid URL"),
});

@connect(null, (dispatch) => ({
  postSalesforceAgreement: (
    qaSummariesId,
    organizationId,
    salesforceId,
    salesforceUrl
  ) =>
    dispatch(
      apiThatThrows.postSalesforceAgreement.action({
        options: {
          body: createBody({
            type: "SalesforceContract",
            organization_id: organizationId,
            salesforce_id: salesforceId,
            salesforce_url: salesforceUrl,
            qa_summary: {
              type: "QASummary",
              id: qaSummariesId,
            },
            relationshipNames: ["qa_summary"],
          }),
        },
      })
    ),
}))
export class AddSalesforceAgreementModal extends PureComponent {
  static propTypes = {
    qaSummariesId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,

    // from @connect
    postSalesforceAgreement: PropTypes.func,
  };

  handleAdd = async (
    { salesforceId, organizationId, salesforceUrl },
    { setErrors }
  ) => {
    const { onSuccess, qaSummariesId, postSalesforceAgreement } = this.props;

    try {
      const response = await postSalesforceAgreement(
        qaSummariesId,
        organizationId,
        salesforceId,
        salesforceUrl
      );

      onSuccess(response);
    } catch (error) {
      const submitErrors = prepareErrorsForForm(error, jsonPointerToFieldName);
      setErrors(submitErrors);
    }
  };

  render() {
    const { onClose } = this.props;

    return (
      <Formik
        initialValues={{
          [FORM_FIELDS.organizationId.name]: "",
          [FORM_FIELDS.salesforceId.name]: "",
          [FORM_FIELDS.salesforceUrl.name]: "",
        }}
        enableReinitialize={true}
        onSubmit={this.handleAdd}
        validationSchema={SalesforceAgreementSchema}
      >
        {({ dirty, errors, isSubmitting, handleSubmit }) => (
          <Modal
            title={trans.APP_DETAILS_RIGHTS__AGREEMENTS_ADD()}
            isOpen={true}
            onClose={onClose}
            actions={
              <ButtonsWrapper>
                <Button type="normal" onClick={onClose} disabled={isSubmitting}>
                  {trans.CANCEL()}
                </Button>

                <Button
                  type="green"
                  processing={isSubmitting}
                  disabled={
                    isSubmitting ||
                    !dirty ||
                    Boolean(Object.entries(errors).length)
                  }
                  onClick={handleSubmit}
                >
                  {trans.ADD()}
                </Button>
              </ButtonsWrapper>
            }
          >
            <Form aria-label="form">
              <TextField {...FORM_FIELDS.salesforceId} />
              <TextField
                {...FORM_FIELDS.salesforceUrl}
                dataTestId="url-field"
                required={false}
              />
              <Label
                required
                text={trans.APP_DETAILS_RIGHTS__AGREEMENTS_MODAL_LABEL_ORGANIZATION()}
              >
                <OrganizationField {...FORM_FIELDS.organizationId} />
              </Label>

              {errors?._error && <Info type="error">{errors._error}</Info>}
            </Form>
          </Modal>
        )}
      </Formik>
    );
  }
}
