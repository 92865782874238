// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useState, useRef, useEffect } from "react";

import Editor from "@monaco-editor/react";
import { useField } from "formik";
import debounce from "lodash-es/debounce";
import PropTypes from "prop-types";

import { FileButton } from "components/buttons";
import { Loader } from "components/elements";
import { Switch } from "components/form/elements";
import { trans } from "src/translations";
import { classes } from "utils/classes";

import { Label, ValidationBadge } from "..";
import { THEMES, useEditorTheme } from "../../elements/CodeEditor";
import styles from "./EditorField.scss";
import { createMixedValidator } from "./fieldUtils";
import { FieldProps } from "./propTypes";

const debounceDelay = 200;

export const EditorField = (props) => {
  const defaultTheme = useEditorTheme();
  const {
    name,
    label,
    language,
    look = "wrapped",
    height = "500px",
    className,
    formats,
    readOnly,
    required = true,
    tooltip,
    uploadActionText,
    validate: validateFn,
    editorTheme = defaultTheme.editorTheme,
    toggleTheme = defaultTheme.toggleTheme,
    dataTestId = "editor-field",
  } = props;

  const isLightTheme = editorTheme === THEMES.light;
  const editorRef = useRef(null);
  const [editorReady, setEditorReady] = useState(false);

  const [field, meta, helpers] = useField({
    ...props,
    type: "text",
    validate: createMixedValidator(name, required, validateFn),
  });

  // This is for clearing field value after form reset
  useEffect(() => {
    if (editorRef.current && !meta.touched) {
      editorRef.current.getModel().setValue(field.value ?? "");
    }
  }, [meta.touched, field.value]);

  // Editor's defaultValue was not setting initial value, so that's the solution
  const handleOnMount = (editor) => {
    editor.getModel().setValue(field.value ?? "");

    if (readOnly) {
      editor.updateOptions({ readOnly: true });
    }
    setEditorReady(true);
    editorRef.current = editor;
  };

  const readFileContent = (e) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      editorRef.current.getModel().setValue(fileReader.result);
      helpers.setValue(fileReader.result);
    };
    fileReader.readAsText(e.target.files[0]);
  };

  const renderButtons = (
    <div className={styles.buttons}>
      {!readOnly ? (
        <FileButton
          disabled={!editorReady}
          formats={formats}
          text={uploadActionText}
          className={styles.uploadButton}
          onChange={readFileContent}
        />
      ) : null}
      <div className={styles.themeSwitch}>
        <p className={styles.modeName} data-test-id={`${dataTestId}-theme`}>
          {isLightTheme ? trans.LIGHT_MODE() : trans.DARK_MODE()}
        </p>
        <Switch
          className={styles.switchButton}
          checked={isLightTheme}
          onChange={toggleTheme}
          dataTestId={`${dataTestId}-theme-switch`}
        />
      </div>
    </div>
  );

  return (
    <div className={classes(styles[look], className)} data-test-id={dataTestId}>
      {label && <Label text={label} tooltip={tooltip} required={required} />}
      {renderButtons}
      <div data-test-id={`${dataTestId}-editor`}>
        <Editor
          height={height}
          language={language}
          loading={<Loader />}
          theme={editorTheme}
          className={styles.editor}
          onChange={debounce((value) => {
            helpers.setValue(value);
            helpers.setTouched(true);
          }, debounceDelay)}
          options={{ minimap: { enabled: false } }}
          onMount={handleOnMount}
        />
      </div>
      <ValidationBadge error={meta.error} touched={meta.touched} />
    </div>
  );
};

EditorField.propTypes = {
  ...FieldProps,
  language: PropTypes.string.isRequired,
  height: PropTypes.string,
  formats: PropTypes.array.isRequired,
  uploadActionText: PropTypes.string.isRequired,
  editorTheme: PropTypes.oneOf([THEMES.light, THEMES.dark]).isRequired,
  toggleTheme: PropTypes.func.isRequired,
};
