import { useParams } from "react-router-dom";

import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import PropTypes from "prop-types";

import { VALIDATION_SCHEMA } from "../constants";
import {
  getManifestQuery,
  updateManifestMutation,
  createManifestMutation,
} from "../gql";
import { adaptDataToRequest } from "../ManifestData";

export function ManifestEditForm({
  manifestData,
  initialValues,
  setErrorsBanner,
  render,
  onCreateCompleted,
}) {
  const { id } = useParams();
  const [updateMutation] = useMutation(
    manifestData?.id ? updateManifestMutation : createManifestMutation,
    {
      refetchQueries: [
        { query: getManifestQuery, variables: { applicationId: id ?? "" } },
      ],
    }
  );
  const handleSubmit = async (formValues, { setErrors }) => {
    const { applicationId, internalApp, ...rest } = formValues;
    const manifest = await adaptDataToRequest(rest);

    try {
      if (manifest && manifestData?.id) {
        // update mode
        await updateMutation({
          variables: {
            input: { id: manifestData.id, manifest },
          },
        });
      } else {
        // create mode
        const id = applicationId.trim();
        await updateMutation({
          variables: {
            input: {
              applicationId: id,
              manifest,
            },
          },
        });
        onCreateCompleted?.(id);
      }
      setErrorsBanner(false);
    } catch (e) {
      setErrors({ requestError: e.message });
      setErrorsBanner(true);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validationSchema={VALIDATION_SCHEMA}
    >
      {(formikProps) => render(formikProps)}
    </Formik>
  );
}

ManifestEditForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  manifestData: PropTypes.shape({
    id: PropTypes.string,
    manifest: PropTypes.object,
  }),
  setErrorsBanner: PropTypes.func.isRequired,
  render: PropTypes.func.isRequired,
  onCreateCompleted: PropTypes.func,
};
