// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useContext } from "react";

import { FieldArray, useFormikContext } from "formik";
import PropTypes from "prop-types";

import { TextField, TextareaField, EditorField } from "components/form/fields";
import {
  ApplicationField,
  MODE,
} from "components/form/fields/ApplicationField";
import { InfoBox } from "components/layout";
import { trans } from "src/translations";

import { FIELDS } from "./constants";
import { ModeContext } from "./context";
import {
  ActionField,
  ContentItemsField,
  FullPageLandscapeField,
  IconField,
  ThumbnailField,
} from "./fields";
import { configurationType } from "./utils";

export const PromotionDetailsAppTab = ({ configuration, name }) => {
  const { isReadonly, forAdmin } = useContext(ModeContext);
  const { getFieldMeta } = useFormikContext();

  const fieldProps = { name };
  const appId = getFieldMeta(`${name}.${FIELDS.APPLICATION.name}`)?.value;

  const {
    appsCount,
    icon,
    thumbnail,
    title,
    description,
    background,
    action,
    json,
    contentItems,
  } = configuration;

  if (!appId && isReadonly) {
    return <InfoBox message={trans.PROMOTION_DETAILS_APP_NOT_AVAILABLE()} />;
  }

  return (
    <FieldArray name={FIELDS.PROMO_APPS.name}>
      <>
        {Boolean(appsCount) && (
          <ApplicationField
            name={`${name}.${FIELDS.APPLICATION.name}`}
            mode={forAdmin ? MODE.distributionAdmin : MODE.distribution}
            isReadonly={isReadonly}
          />
        )}
        {thumbnail && <ThumbnailField {...fieldProps} />}
        {icon && <IconField {...fieldProps} />}
        {background && <FullPageLandscapeField {...fieldProps} />}
        {title && (
          <TextField
            {...FIELDS.TITLE}
            name={`${name}.${FIELDS.TITLE.name}`}
            disabled={isReadonly}
          />
        )}
        {description && (
          <TextareaField
            {...FIELDS.DESCRIPTION}
            name={`${name}.${FIELDS.DESCRIPTION.name}`}
            disabled={isReadonly}
          />
        )}
        {action && <ActionField {...fieldProps} />}
        {json && (
          <EditorField
            {...FIELDS.JSON}
            name={`${name}.${FIELDS.JSON.name}`}
            readOnly={isReadonly}
            encoded={false}
          />
        )}
        {contentItems && <ContentItemsField {...fieldProps} />}
      </>
    </FieldArray>
  );
};

PromotionDetailsAppTab.propTypes = {
  name: PropTypes.string,
  isReadonly: PropTypes.bool,
  configuration: configurationType.isRequired,
};
