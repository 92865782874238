// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { gql } from "@apollo/client";

export const getManifestQuery = gql`
  query manifest($applicationId: ID!) {
    manifest(applicationId: $applicationId) {
      id
      manifest {
        type
        resolution
        transparent
        defaultBackgroundColor
        displayName
        bgPersistence
        displayIcon
        userAgent
        allowsAudioOnlyPlayback
        allowScriptsToCloseWindows
        spatialNavigationEnabled
        inputMethod
        historyOnBack
        blockMixedActiveContent
        xhrOriginCheckEnabled
        blockMixedXhrContent
        blockMixedFetchContent
        noCorsHeaderGuard
        keyRemapping {
          key
          value
        }
        custom {
          key
          value
          description
        }
        userScript
        zoom
      }
    }
  }
`;
