// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Info } from "components/feedback";
import { trans } from "src/translations";

import styles from "./TableNoResults.scss";

/**
 * Information about no matching results, errors or loading state.
 * Should be placed inside `<TableBody>`.
 */
export class TableNoResults extends Component {
  static propTypes = {
    /** Message to display when there is no data. */
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** If true, the provided `message` prop will be displayed as error */
    isError: PropTypes.bool,
    /** If true, overrides `message` with "Loading..." */
    loading: PropTypes.bool,
    colspan: PropTypes.number /* Number of columns in table */,
  };

  static defaultProps = {
    message: trans.TABLE_EMPTY(),
    isError: false,
    loading: false,
    colspan: 10,
  };

  render() {
    const { message, isError, loading, colspan } = this.props;

    const tableMessage = loading ? `${trans.LOADING()}...` : message;

    return (
      <tbody>
        <tr>
          <td colSpan={colspan} className={styles.container}>
            {isError ? (
              <Info
                type="error"
                className={styles.errorInfo}
                dataTestId="message-no-results"
              >
                {tableMessage}
              </Info>
            ) : (
              <div data-test-id="message-no-results-empty">{tableMessage}</div>
            )}
          </td>
        </tr>
      </tbody>
    );
  }
}

export default TableNoResults;
