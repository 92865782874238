// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment, PureComponent } from "react";

import PropTypes from "prop-types";

import { ButtonsWrapper, Button } from "components/buttons";
import { Loader } from "components/elements";
import { Info } from "components/feedback";
import {
  Section,
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
  TableRowColumnExtended,
  TableNoResults,
} from "components/layout";
import { ActionMenu, MenuItem } from "components/popups";
import { Link } from "components/typography";
import { Permissions, ROLES } from "containers/Permissions";
import { trans } from "src/translations";

import styles from "./SalesforceAgreements.scss";
import { AddSalesforceAgreementModal } from "./SalesforceAgreementsModals/AddSalesforceAgreementModal";
import { DeleteSalesforceAgreementWarningModal } from "./SalesforceAgreementsModals/DeleteSalesforceAgreementWarningModal";

export class SalesforceAgreements extends PureComponent {
  static propTypes = {
    qaSummariesId: PropTypes.string.isRequired,
    agreements: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        salesforceId: PropTypes.string.isRequired,
        salesforceUrl: PropTypes.string,
        organizationId: PropTypes.string.isRequired,
        organizationName: PropTypes.string.isRequired,
      })
    ),
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    deleteAgreement: PropTypes.func.isRequired,
    onAddAgreement: PropTypes.func.isRequired,
    organizationsWithAcceptedTargets: PropTypes.arrayOf(PropTypes.string),
  };

  state = {
    addAgreementModalOpen: false,
    deleteWarningModalOpen: false,
    deleteAgreementError: false,
  };

  openAddModal = () => {
    this.setState({
      addAgreementModalOpen: true,
    });
  };

  deleteAgreement = async (deleteAgreementId, organizationId) => {
    const { agreements, deleteAgreement, organizationsWithAcceptedTargets } =
      this.props;

    const isAccepted =
      organizationsWithAcceptedTargets.includes(organizationId);
    const organizationAgreements = agreements.filter(
      (agreement) => agreement.organizationId === organizationId
    );
    if (isAccepted && organizationAgreements.length === 1) {
      this.setState({ deleteWarningModalOpen: true });
      return;
    }

    try {
      await deleteAgreement(deleteAgreementId);
      this.handleClose();
    } catch (e) {
      this.handleClose();
      this.setState({ deleteAgreementError: true });
    }
  };

  handleSuccess = async (response) => {
    const { onAddAgreement } = this.props;

    await onAddAgreement(response);

    this.handleClose();
  };

  handleClose = () => {
    this.setState({
      addAgreementModalOpen: false,
      deleteWarningModalOpen: false,
      deleteAgreementError: false,
    });
  };

  renderTable = () => {
    const { agreements } = this.props;

    return (
      <Table>
        <TableHeader>
          <TableHeaderColumn>
            {trans.APP_DETAILS_RIGHTS__AGREEMENTS_TABLE_ORGANIZATION()}
          </TableHeaderColumn>
          <TableHeaderColumn>
            {trans.APP_DETAILS_RIGHTS__AGREEMENTS_TABLE_SALESFORCE_ID()}
          </TableHeaderColumn>
          <TableHeaderColumn />
        </TableHeader>

        {agreements.length === 0 && (
          <TableNoResults
            colspan={3}
            message={trans.APP_DETAILS_RIGHTS__AGREEMENTS_NO_AGREEMENTS()}
          />
        )}

        {agreements.length > 0 && (
          <TableBody>
            {agreements.map(
              ({
                id,
                organizationId,
                organizationName,
                salesforceId,
                salesforceUrl = "",
              }) => (
                <TableRow key={id}>
                  <TableRowColumnExtended
                    to={`/organization/${organizationId}/details`}
                    text={organizationName}
                  />

                  <TableRowColumn>
                    {salesforceUrl ? (
                      <Link href={salesforceUrl} target={"blank"}>
                        {salesforceId}
                      </Link>
                    ) : (
                      salesforceId
                    )}
                  </TableRowColumn>

                  <TableRowColumn className={styles.right}>
                    <ActionMenu>
                      <Permissions allowed={[ROLES.moderator.contentManager]}>
                        {(isAllowedToEdit) => (
                          <MenuItem
                            dataTestId={"delete-agreement-button"}
                            onClick={() =>
                              this.deleteAgreement(id, organizationId)
                            }
                            disabled={!isAllowedToEdit}
                          >
                            {trans.DELETE()}
                          </MenuItem>
                        )}
                      </Permissions>
                    </ActionMenu>
                  </TableRowColumn>
                </TableRow>
              )
            )}
          </TableBody>
        )}
      </Table>
    );
  };

  render() {
    const { qaSummariesId, loading, error } = this.props;
    const {
      addAgreementModalOpen,
      deleteWarningModalOpen,
      deleteAgreementError,
    } = this.state;

    if (loading || error) {
      return (
        <Section header={trans.APP_DETAILS_RIGHTS__AGREEMENTS_HEADER()}>
          {loading && <Loader />}
          {error && !loading && (
            <Info type="error">{trans.DEFAULT_ERROR_MESSAGE()}</Info>
          )}
        </Section>
      );
    }

    return (
      <Fragment>
        <Section header={trans.APP_DETAILS_RIGHTS__AGREEMENTS_HEADER()}>
          <div className={styles.text}>
            {trans.APP_DETAILS_RIGHTS__AGREEMENTS_DESCRIPTION()}
          </div>

          {this.renderTable()}
        </Section>

        <ButtonsWrapper position={"center"}>
          <Permissions allowed={[ROLES.moderator.contentManager]}>
            {(isAllowedToEdit) => (
              <Button
                dataTestId={"add-agreement-button"}
                onClick={this.openAddModal}
                disabled={!isAllowedToEdit}
              >
                {trans.APP_DETAILS_RIGHTS__AGREEMENTS_ADD()}
              </Button>
            )}
          </Permissions>
        </ButtonsWrapper>

        {deleteAgreementError && (
          <Info type="error">{trans.DEFAULT_ERROR_MESSAGE()}</Info>
        )}

        {addAgreementModalOpen && (
          <AddSalesforceAgreementModal
            qaSummariesId={qaSummariesId}
            onClose={this.handleClose}
            onSuccess={this.handleSuccess}
          />
        )}

        {deleteWarningModalOpen && (
          <DeleteSalesforceAgreementWarningModal onClose={this.handleClose} />
        )}
      </Fragment>
    );
  }
}
